import { graphql, Link } from 'gatsby';
import React from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import Disclosure from '~components/common/Disclosure';
import FeatureList from '~components/common/FeatureList';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  return (
    <Layout
      location={location}
      title="Schlafkrankheit: So kannst du besser schlafen"
      desc="✅ Ein- und Durchschlafstörungen sind für viele Menschen sehr belastend. Dieses einfache Verfahren unterstützt Betroffene der Schlafkrankheit."
      active="Schlafkrankheit"
      green
    >
      <PageHeader title="Schlafkrankheit: So kannst du besser schlafen" image={data.bildHeader} classNameImage="rounded-2xl mx-auto" wrapperClass="">
        <P className="mb-3">
          Im Schnitt verbringt der Mensch 24 Jahre schlafend, jede dritte Person davon mit Schlafkrankheit. Zu den verbreiteten Schlafkrankheiten zählen vorzugsweise Ein- oder Durchschlafstörungen, die den Schlaf-, Wachrhythmus stören, aber
          auch das nächtliche Zähneknirschen, Alpträume, Schnarchen oder Schlafapnoe.
        </P>

        <P className="mb-6">Doch was kannst du nun konkret tun, um deine Schlafqualität zu verbessern und endlich erholt aufzuwachen? Genau das wirst du auf dieser Seite erfahren. Zudem sprechen wir über:</P>

        <FeatureList
          items={[
            { text: 'Was für Schlafkrankheiten gibt es?' },
            { text: 'Was kann man gegen Schlafprobleme tun?' },
            { text: 'Was tun, wenn man trotz Müdigkeit nicht schlafen kann?' },
            { text: 'Schlafkrankheit mit Entspannungsverfahren lindern!' },
            { text: 'Mit welchen Gewohnheiten lässt sich dein Schlaf verbessern?' },
            { text: 'Unsere Kursempfehlung für dich!' }
          ]}
          className="space-y-5"
        />
      </PageHeader>

      <Container className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <P className="mb-3">
              Dass etwas mit unserem Schlaf nicht stimmt, merken wir in der Regel am nächsten Morgen, wenn wir völlig gerädert mit dem falschen Fuß aufstehen und literweise Kaffee trinken müssen, um richtig wach zu werden. Dennoch spielt
              Schlaf eine untergeordnete Rolle, wenn es um Lebensqualität geht. Dabei ist das doch offensichtlich genau die Stellschraube, um dein Energieniveau aufrechtzuerhalten!
            </P>
            <P>
              Und das ist das Stichwort: Im Leben dreht sich alles um Energie. Menschen mit viel Energie sind produktiv, zielorientiert und erfolgreich. Eigenschaften, die jeder gerne hätte. Doch der Durchschnitt der Menschen kämpft häufig
              mit Energiemangel, fühlt sich schwach und antriebslos. Während wir viel Zeit darauf verwenden, unsere Ernährung und Bewegung zu optimieren, wird Schlaf oftmals missachtet.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Was für Schlafkrankheiten gibt es?</H2>
            <P className="mb-3">
              Um etwas gegen Schlafprobleme tun zu können, muss erst einmal klar sein, um welche Art von Schlafproblemen es sich denn handelt. Denn es werden ca. 80 unterschiedliche Schlafkrankheiten unterschieden. Viele davon sind
              phasenabhängig, gar nicht schlimm und können mit dem Verändern von Schlafgewohnheiten gemildert werden. Andere Formen der Schlafkrankheiten benötigen ärztliche Behandlung, was aber nicht bedeutet, dass du als betroffene Person
              nicht auch von der Schlafhygiene profitierst.
            </P>

            <dl className="mb-3 space-y-3">
              <Disclosure title="Insomnien">
                <P>
                  Etwa 6 % der Bevölkerung leiden unter Schlafmangel, die durch zu nicht einschlafen können, oder aber häufiges Aufwachen in der Nacht entsteht. Bestimmt kennst du den Song Insomnia von Faithless, in dem der Künstler seinen
                  Schlafproblemen Ausdruck verleiht. Von Insomnie wird dann gesprochen, wenn du mindestens dreimal in der Woche unter Ein- oder Durchschlafstörungen leidest.
                </P>
              </Disclosure>
              <Disclosure title="Hypersomnien">
                <P>
                  Die Schlafmenge scheint normal zu sein, auch das Ein- und Durchschlafen gelingt. Der zirkadiane Tag-Nacht-Rhythmus ist normal. Dennoch tritt eine Form der Tagesschläfrigkeit auf. Ursachen sind beispielsweise Narkolepsie,
                  traumatische Hirnschädigungen, oder Medikamenten- bzw. Substanzenmissbrauch.
                </P>
              </Disclosure>
              <Disclosure title="Parasomnien">
                <P>Betroffene dieser Schlafkrankheit leiden unter Schlafwandeln, nächtlichen Albträumen oder schlafbezogenen Essstörungen. Auch die unbewusste Blasenentleerung, oder nächtliches Stöhnen, wird den Parasomnien zugeordnet.</P>
              </Disclosure>
              <Disclosure title="Atmungsstörungen (im Schlaf)">
                <P>
                  In ziemlich vielen Schlafzimmern wird geschnarcht, bis sich die Balken biegen, oder einer auf die Couch umzieht. Schnarchen ist durch eine andere Lagerung schnell behoben und ist ungefährlich, aber nervig. Bei der
                  Schlafapnoe ist das anders. Hierbei handelt es sich um Atemaussetzer, von denen der Schlafende nichts mitbekommt. Durch häufige Atemaussetzer kann es zu einer Mangelversorgung mit Sauerstoff kommen. Anzeichen hierfür sind
                  Konzentrationsprobleme, Tagesmüdigkeit oder eben auch nächtliches Schnarchen und kommt besonders häufig bei übergewichtigen Menschen, oder bei Erkrankungen der Atemwege vor.
                </P>
              </Disclosure>
              <Disclosure title="Zirkadiane Rhythmusstörungen">
                <P>
                  Menschen, die in wechselnden Schichten arbeiten, sind besonders häufig von wechselnden Schlaf-Wach-Rhythmen betroffen. Hierdurch können Ein- oder Durchschlafstörungen begünstigt werden. Sie können allerdings auch durch
                  Medikamenten- und Subtanzmittelmissbrauch, oder organische Erkrankungen entstehen.
                </P>
              </Disclosure>
              <Disclosure title="Bewegungsstörungen">
                <P>
                  Jede fünfte Person in Deutschland knirscht und reibt mit den Zähnen. Die wiederkehrenden Bewegungen, ausgelöst durch die Kiefer-Kaumuskulatur, werden auch Bruxismus genannt und sind besonders belastend. Denn durch
                  nächtliches Knirschen können viele Folgeerscheinungen wie Zahnschmerzen, Verspannungen, Kopfschmerzen, Kieferschmerzen auftreten. (Siehe auch: Knirschen endlich loswerden und Progressive Muskelentspannung) Neben dem
                  Knirschen zählt auch das Restless-Legs-Syndrom zu den nächtlichen Bewegungsstörungen. Muskelzuckungen, sogenannte autogene Entladungen, hingegen sind ungefährlich und ein Zeichen der Muskelentspannung.
                </P>
              </Disclosure>
            </dl>
          </div>

          <div>
            <H2 className="mb-5 font-display">Welche Krankheiten verursachen Schlafstörungen?</H2>
            <P>
              Während viele Schlafstörungen ihren Ursprung auf körperlicher Ebene haben, gibt es natürlich auch mentale Faktoren und Ursachen für Schlafstörungen. Schnarchen und Schlafapnoe beispielsweise sind bedingt durch schwache Hals-
              und Rachenmuskulatur und können trainiert werden. Narkolepsie hat einen neurologischen Ursprung, der Einfluss auf den Muskeltonus nimmt. Aber auch die typische Erkältung, Asthma, Herz-Kreislauf-Erkrankungen, chronische
              Schmerzen und entzündliche Prozesse wie Rheuma oder Arthritis nehmen Einfluss auf deine Schlafqualität, sowie deren Medikamente. Neben den körperlichen Ursprüngen sind natürlich auch die psychischen Erkrankungen zu erwähnen,
              die sich ebenfalls nachteilig auf Schlaf auswirken.
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Was tun, wenn man trotz Müdigkeit nicht schlafen kann?</H2>
            <P className="mb-3">
              Es klingt ein wenig paradox, doch wenn du nicht müde bist, steht auf. Das Gefühl nicht ausgelastet zu sein entsteht häufig durch Mangelbewegung und durch das Gefühl, noch etwas vergessen oder nicht erledigt zu haben.
              Grübeleien hindern dich am Schlafen. Daher solltest du immer Zettel und Stift neben dem Bett platzieren, falls dir etwas Wichtiges einfällt.
            </P>
            <P className="mb-3">
              Die beste Methode, um müde zu werden, ist und bleibt allerdings Energie zu verbrauchen. Abends solltest du nur moderaten Sport machen, also Yoga, oder einen Spaziergang an der frischen Luft machen. Perfekt geeignet wäre auch
              die Progressive Muskelentspannung, die du mit uns im Onlinekurs erlernen kannst. Alternativ können hier auch ein Fußbad mit Lavendelöl oder aber eine wohltuende Massage deines Herzensmenschen helfen.
            </P>
            <P>
              Viele Menschen greifen leider zur Wunderpille und nehmen bereitwillig Schlafmedikamente ein. Das ist absolut keine Empfehlung und kann Schlafkrankheiten fördern. Denn dein Körper kann sich an die Medikamente gewöhnen und eine
              Abhängigkeit entwickeln. Deshalb schlagen wir dir stattdessen vor, mit Entspannungsverfahren wie dem{' '}
              <Link to="/kurse/autogenes-training-onlinekurs/" className="underline">
                Autogenen Training
              </Link>{' '}
              zu arbeiten. Die Wirkung dieses Entspannungsverfahrens auf Schlafkrankheiten ist erwiesen.{' '}
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Schlafkrankheit mit Entspannungsverfahren lindern</H2>
            <P className="mb-3">
              Da die Wirkung von Entspannungsverfahren auf Schlafkrankheiten erwiesen ist, möchten wir dir die wichtigsten Entspannungsmethoden vorstellen und dir Anleitungen mit an die Hand geben, die du ausprobieren kannst. Wir listen die
              Entspannungsverfahren nach Schwierigkeitsgraden auf und laden dich ein, viele davon auszuprobieren!
            </P>
            <dl className="mb-3 space-y-3">
              <Disclosure title="Atemübungen">
                <P>
                  Unzählige Atemübungen sind heute gut erforscht und haben nachweislich positive Auswirkungen auf deinen Schlaf. Es gibt ziemlich einfache Formen wie die Atemverlängerung, die du in unserem{' '}
                  <Link to="/podcast/folgen/03/atemtraining-atempausen/" className="underline">
                    Podcast zu Atempausen
                  </Link>{' '}
                  anhören kannst, oder auch sehr anspruchsvolle wie die Wim Hof, oder Feueratmung.
                </P>
              </Disclosure>
              <Disclosure title="Traumreisen">
                <P>
                  Traum- oder Fantasiereisen sind eine geführte Form der Entspannung, bei der du sanft in den Schlaf übergehen kannst. Die visuelle Vorstellung bestimmter Umgebungen beruhigt Gedankenkreise und lässt Urlaubsstimmung
                  aufkommen. Sie sind bestens geeignet, um einzuschlafen und abzuschalten. Unsere aktuellste Traumreise findest du ebenfalls auf YouTube.{' '}
                  <a href="https://www.youtube.com/watch?v=p-kFtKKS59o" className="underline" target="_blank" rel="noopener noreferrer">
                    Klick hier!
                  </a>
                </P>
              </Disclosure>
              <Disclosure title="Progressive Muskelentspannung ">
                <P>
                  Der Wechsel aus An- und Entspannung wirkt positiv auf deine Muskelspannung und baut Verspannungen und Rückenschmerzen ab. Das geniale ist, dass du mit Progressiver Muskelentspannung auch dem Knirschen Einhalt gebietest und
                  so Schmerzen in deiner Kiefermuskulatur senkt. Du findest in unserem Blogbeitrag{' '}
                  <Link to="/blog/entspannung-zum-einschlafen/" className="underline">
                    Entspannung zum Einschlafen
                  </Link>{' '}
                  eine kostenlose Hörprobe.
                </P>
              </Disclosure>
              <Disclosure title="Sanfte Bewegungen">
                <P>
                  Yoga, Tai-Chi und Qi-Gong zählen zu den bekanntesten Bewegungsformen, die eher in Ruhe stattfinden. Beim Yoga erlebst du einen Lebensstil, der sich in Atmung, Bewegung und Ernährung widerspiegelt. Es gibt mittlerweile über
                  13 Formen von Yoga Ausprägungen. Viele Formen wirken entspannend und helfen dir besser zu schlafen. Qi-Gong und Tai-Chi zählen zu den sanften Bewegungsformen, die in einer Art Übungsabfolge arbeiten. Via YouTube gibt es
                  Anleitungen für verschiedene Übungen.
                </P>
              </Disclosure>
              <Disclosure title="Autogenes Training">
                <P>
                  Mit sieben Grundformeln verankerst du bei diesem passiven Entspannungsverfahren unterschiedliche Zustände. Auf unserem{' '}
                  <a href="https://www.youtube.com/watch?v=ZlgwAp163vc" className="underline" target="_blank" rel="noreferrer noopener">
                    YouTube Kanal
                  </a>{' '}
                  haben wir unsere erste Kursstunde für dich veröffentlicht, sodass du einen exklusiven Einblick in unseren{' '}
                  <Link to="/kurse/autogenes-training-onlinekurs/" className="underline">
                    Autogenes Training Onlinekurs
                  </Link>
                  . Autogenes Training unterstützt dich nicht nur beim Einschlafen, sondern ist zudem sehr wirksam bei Bluthochdruck!
                </P>
              </Disclosure>
              <Disclosure title="Meditation">
                <P>
                  Neben der stillen Sitzmeditation, die viele mit dem Thema verbinden, kannst du auch meditieren in dem du einen stillen Abendspaziergang machst und dich einfach auf jeden Schritt konzentrierst, im Sommer gerne auch barfuß.
                  Als Einsteiger solltest du mit wenigen Minuten Meditation im Liegen beginnen und das völlig ohne Ziel. Es ist okay, wenn deine Gedanken abschweifen. Versuche dich auf deinen Atem zu konzentrieren, das reicht völlig aus.
                </P>
              </Disclosure>
            </dl>
          </div>
          <div>
            <H2 className="mb-5 font-display">Mit welchen Gewohnheiten lässt sich dein Schlaf verbessern?</H2>

            <dl className="mb-3 space-y-3">
              <Disclosure title="Umgebung">
                <P>
                  Du hast wirklich viele Ansätze, um deine Schlafqualität zu verbessern. Die einfachste Methode ist die Raumgestaltung selbst. Dein Schlafzimmer ist dein Schlafzimmer und sollte ausschließlich für Schlaf und Romantik da
                  sein. Arbeiten oder Lernen im Bett sind absolute No-Go’s und sollten vermieden werden, genau wie der Fernseher oder die Musikanlage. Dein Gehirn braucht Klarheit und Ruhe, daher gestalte dein Schlafzimmer mit freundlichen
                  Farben, netten Gardinen und möglichst reizarm. Achte auf eine angenehme Atmosphäre und gute Luft.
                </P>
              </Disclosure>
              <Disclosure title="Elektrogeräte">
                <P>
                  Wenn du Fernseher und Radio bereits verbannt hast, dann lass doch auch gleich dein Handy draußen. Zugegeben, auch ich lasse mich durch mein Handy wecken, versuche es aber lediglich als Wecker zu nutzen und habe es sonst im
                  Schlafzimmer auf Flugmodus. Handys, bzw. Displays aller Art nutzen blaues Licht, um deine Aufmerksamkeit zu erhöhen. Daher solltest du entweder einen Blaulichtfilter verwenden, oder aber gänzlich auf digitalen Medienkonsum
                  30 - 120 Minuten vor dem Schlafen verzichten.
                </P>
              </Disclosure>
              <Disclosure title="Dein Abendritual">
                <P>
                  Um deinen Tag abzuschließen und tatsächlich in den „Zu-Bett-Geh-Modus“ zu kommen, ist ein Tagesabschluss wichtig. Das kann das wiederkehrende Teeritual sein, oder aber das Journaling. Reflektiere deinen Tag und notiere
                  Aufgaben, die du vielleicht mit in den neuen Tag nimmst. Gerne kannst du dir auch eine To-Do Liste vorbereiten, um nichts mehr zu vergessen. Auch das oben erwähnte Fußbad mit Lavendelöl wirkt entspannend und hilft dir.
                  Probiere so viel wie möglich aus und mache mehr von dem, was dir guttut.
                </P>
              </Disclosure>
              <Disclosure title="Fester Zeitplan">
                <P>
                  Ist es nicht beruhigend, zu wissen, wann dein Arbeitstag beginnt und endet? Wieso nutzen wir dieses Sicherheitstool nicht auch für deinen Schlaf und schaffen Verbindlichkeit? Regelmäßige Zubettgeh- und Aufstehzeiten
                  steigern deine Schlafqualität um ein Vielfaches. Versuche für einen Monat immer zu einer bestimmten Uhrzeit ins Bett zu gehen (30 Minuten vor Einschlafzeitpunkt) und stehe auch immer wieder sofort auf, wenn der Wecker
                  klingelt. Ganz ohne snoozen. Es wäre sehr günstig, wenn du eine Art Dokumentation führst, um zu erfahren, ob 6, 7 oder sogar 8 Stunden deine optimale Schlaflänge sind. Vielleicht machst du dir jeden Morgen einen Smiley in
                  deinen Terminkalender, der fröhlich, neutral oder traurig guckt. Oder du verwendest eine Skalierung von 1 bis 10, wobei 10 für den besten Schlaf steht.
                </P>
              </Disclosure>
              <Disclosure title="Lebensstil">
                <P>
                  Hilft das alles nichts, oder nur bedingt weiter, schau doch mal, ob du an deiner Ernährung etwas verändern kannst. Die fettige Pizza direkt vorm Einschlafen liegt dir schwer im Magen, während die Gemüsepfanne gut
                  verdaulich wirkt. Vielleicht hast du deine Schlafprobleme aber auch aufgrund von Koffein, Alkohol oder Medikamenten? Hinterfrage also, was du wann zu führst. 4-6 Stunden vor dem Schlafen verzichtest du am besten auf
                  Koffein, Nikotin und Alkohol. Zwar hilft dir der Wein am Abend erst einmal besser einzuschlafen, doch die Schlafqualität nimmt dadurch stark ab.
                </P>
              </Disclosure>
            </dl>
          </div>

          <div>
            <H2 className="mb-5 font-display">Unsere Kursempfehlung für dich!</H2>
            <P className="mb-3">
              Nun hast du ja einiges zum Thema Schlafproblemen gelesen und wahrscheinlich auch schon die eine oder andere Strategie für dich gefunden. Wir möchten dir unseren zertifizierten Onlinekurs Autogenes Training sehr ans Herz legen.
              Mit dem kannst du deine Schlafqualität nämlich extrem verbessern. Du kannst dich hier über den Kurs informieren, oder direkt buchen. Dabei trainierst du Orts- und Zeit unabhängig und das fast umsonst. Denn nach Kursabschluss
              erhältst du mindestens 75 Euro von deiner Krankenkasse zurück.
            </P>
            <div className="mb-3">
              <Button text="Den richtigen Kurs finden" to="/kurse/kursquiz/" />
            </div>
            <Button text="Soviel zahlt deine Krankenkasse" to="/kurse/krankenkasse/" />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildHeader: file(relativePath: { eq: "pages/herausforderungen/schlafkrankheit/schlafkrankheit_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    videoCover: file(relativePath: { eq: "video/zaehneknirschen.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
